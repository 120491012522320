<template>
  <div>
    <!-- dict download92724CDBA550E8FEE1F2624C6DB09BB3.swf -->
    <!-- pic20A3FFAD-2A2A-40E1-9C37-A85FB3DACED5.jpg -->
    <img class="rounded img-large" src="">
    <p>
      <b>Great Western LEGO Show</b><br>
      Swindon, UK<br>
      October 3 and 4, 2009
    </p>
    <p>
      The LEGO Universe team can't wait for the Great Western LEGO Show in Swindon!  This is a super event for families and LEGO fans of all ages where you will see amazing LEGO displays and can even help the Brickish Association build a Massive Mosaic!
    </p>
    <p>
      Hope to see you there!
    </p>
  </div>
</template>
